import logo from './background.png';
import './App.css';
import {Dimensions, Image} from 'react-native';
import {useEffect} from "react";

function App() {

    const dimensions = Dimensions.get('window');
    const imageWidth = dimensions.width;
    const imageHeight = dimensions.height;

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

  return (
    <div className="App">
        <header className="App-header">
            <div className="head-text">
                <div className="head-image">
                    <Image
                        style={{height: imageHeight, width: imageWidth, opacity: 0.4}}
                        resizeMode="cover"
                        source={logo}
                    />
                </div>
                <div className='text-on-image'>
                    <h1>
                        <span style={{color: "#ee2a2a"}}>M</span>
                        <span style={{color: "#2aee98"}}>c</span>
                        <span style={{color: "#f5d03b"}}>Origins</span>
                    </h1>
                    <h3 style={{color: "white"}}> Relaunch Coming Soon! </h3>
                    <a href="https://twitter.com/Mcorigins" className="twitter-follow-button"
                       data-show-count="true" data-size="large">Follow @x</a><br/>
                    <iframe title="discord" src="https://discord.com/widget?id=127589439001198592&theme=dark" width="350" height="500"
                            allowTransparency="true" frameBorder="0"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                </div>
            </div>
        </header>
    </div>
  );
}

export default App;
